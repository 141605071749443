<template>
    <div v-if="entry.relatedArticles" class="journal-entry-related-articles container-fluid">
        <div class="related-articles-header row">
            <div class="col-12 col-lg-3">
                <h5 class="section-heading">{{ $tc("journal.relatedArticles.heading") }}</h5>
            </div>
        </div>
        <div class="related-articles-overview row" ref="relatedArticlesOverview">
            <journal-preview-blocks class="col-12" :entries="relatedArticles"></journal-preview-blocks>
        </div>
    </div>
</template>

<script>
import { Journal } from "@/http/Journal";

import JournalPreviewBlocks from "@/components/journal/JournalPreviewBlocks";

export default {
    name: "JournalEntryRelatedArticles",
    components: {
        JournalPreviewBlocks,
    },
    props: ["entry"],
    data() {
        return {
            relatedArticles: null,
            selectedTag: null,
        };
    },
    mounted() {
        if (this.entry.relatedArticles) {
            this.relatedArticles = this.entry.relatedArticles;
        }
    },
};
</script>
