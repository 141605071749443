import { getHttp } from './fetchApi';

export const Coaching = {
    URL: '/coaching',

    // getAll(){
    //     return getHttp(`${this.URL}.json`)
    // },

    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    },

}
