<template>
    <div v-if="block.entries && block.entries.length" class="block block-call-to-action-entries block-coaching-entries position-relative">
        <previewBlockCoachingEntry v-for="(entry, index) in block.entries" :key="index" :entry="entry" @click="openJournalArticle($event, getEntryThemeClass(entry))"></previewBlockCoachingEntry>
        <div v-if="showLeaveTransitionOverlay" class="leave-transition-overlay" :class="{ cover: animateLeaveTransitionOverlay }" :style="leaveTransitionOverlayStyle"></div>
    </div>
</template>

<script>
import previewBlockCoachingEntry from '@/components/previewBlocks/CoachingEntry';

export default {
    name: "coachingEntries",
    components: {
        previewBlockCoachingEntry,
    },
    props: {
        block: null,
        // limit: {
        //     default: 0,
        // },
    },
    computed: {},
    data() {
        return {
            showLeaveTransitionOverlay: false,
            animateLeaveTransitionOverlay: false,
            leaveTransitionOverlayStyle: {},
        };
    },
    watch: {},
    mounted() {
        this.resetLeaveTransitionOverlay();
    },
    unmounted() {
        this.resetLeaveTransitionOverlay();
    },
    activated() {
        this.resetLeaveTransitionOverlay();
        window.dispatchEvent(new Event('resize'));
    },
    deactivated() {
    },
    methods: {
        openJournalArticle(e, theme) {
            var tempMs = Date.now();
            if (!this.showLeaveTransitionOverlay) {
                this.$store.commit('view/setTransitionDuration', 900);
                const currentTarget = e.currentTarget;
                var boundingClientRect = currentTarget.getBoundingClientRect();
                this.leaveTransitionOverlayStyle = {
                    backgroundColor: window.getComputedStyle(currentTarget).backgroundColor,
                    left: boundingClientRect.left + 'px',
                    right: window.innerWidth - boundingClientRect.right + 'px',
                    top: boundingClientRect.top + 'px',
                    bottom: window.innerHeight - boundingClientRect.bottom + 'px',
                };
                this.showLeaveTransitionOverlay = true;
                var that = this;
                setTimeout(function () {
                    that.leaveTransitionOverlayStyle.left = 0;
                    that.leaveTransitionOverlayStyle.top = document.getElementById('site-header').clientHeight + 'px';
                    that.leaveTransitionOverlayStyle.right = 0;
                    that.leaveTransitionOverlayStyle.bottom = 0;
                }, 20);
                setTimeout(function () {
                    if (!that.$store.getters['view/get'].pageClass.includes(theme)) {
                        // ios workaround timing problem pageClass: do not set transparent class if theme color already is set
                        that.$store.commit('view/setPageClass', theme + ' transparent-header');
                    }
                }, 900);
            }
        },
        resetLeaveTransitionOverlay() {
            this.showLeaveTransitionOverlay = false;
            this.animateLeaveTransitionOverlay = false;
            this.leaveTransitionOverlayStyle = {};
        },
        getEntryThemeClass(entry) {
            let themeClass = 'theme-default';
            if (entry && entry.theme) {
                themeClass = 'theme-custom-colors';
            }
            return themeClass;
        },
    },
};
</script>

<style scoped>
.leave-transition-overlay {
    position: fixed;
    transition: all 880ms ease;
    z-index: 1500;
}
</style>

