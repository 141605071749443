<template>
    <div class="block block-info-dropdown block-text-standard container-fluid" :class="{'active':active}">
        <div class="row content-block-row">
            <div class="col-12 col-md-9 mx-auto col-lg-6 text-0 d-flex flex-column">
                <div class="info-header">
                    <svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><defs><clipPath id="a"><path fill="currentColor" d="M0 0h16v16H0z"/></clipPath></defs><g fill="currentColor" clip-path="url(#a)"><path d="M8 1.334A6.666 6.666 0 1 1 1.334 8 6.673 6.673 0 0 1 8 1.334M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8" data-name="Pfad 3347"/><path d="M7.385 7.385h1.231v4.923H7.385z"/><path d="M9.231 4.923A1.231 1.231 0 1 1 8 3.692a1.23 1.23 0 0 1 1.231 1.231"/></g></svg>
                    <h5 class="info-title" v-html="block.heading || 'Info'" @click="this.active = !this.active"></h5>
                </div>
                <Collapse :when="active" class="v-collapse">
                    <div class="info-content" v-if="block.text" v-html="block.text"></div>
                </Collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { Collapse } from 'vue-collapsed';
export default {
    components: {
        Collapse,
    },
    name: 'infoDropdown',
    props: ['block'],
    data() {
        return {
            active: false,
        };
    },
};
</script>
<style>
.v-collapse {
    transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
</style>
