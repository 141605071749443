import { getHttp } from './fetchApi';

export const Search = {
    URL: '/search',

    get(textSearch){
        return getHttp(`${this.URL}.json`, { s: textSearch })
    },

    getQuickLinks(){
        return getHttp(`/quick-links.json`)
    },

    getTagCategories(){
        return getHttp(`/tag-categories.json`)
    },

}
