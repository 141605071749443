<template>
    <div class="container-fluid block block-team">
        <div v-if="block.sectionHeading" class="row content-block-row">
            <div class="col-12">
                <h2 class="project-section-heading" v-html="block.sectionHeading"></h2>
            </div>
        </div>
        <div class="row content-block-row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-12 mb-5" v-for="person in block.people" v-bind:key="person">
                <img :src="person.photo" :alt="person.name" class="img-fluid" />
                <div class="text-base mt-1">{{ person.name }}</div>
                <div class="text-sm">{{ person.position }}</div>
                <div class="text-sm mb-3">
                    <a :href="`mailto:${person.email}`" target="blank">{{ person.email }}</a>
                </div>
                <div class="text-sm text-gray" v-if="person.readmore" v-html="person.description"></div>
                <button class="text-sm text-gray read-more" v-show="person.description" v-if="!person.readmore" @click="person.readmore = true">Mehr</button>
                <button class="text-sm text-gray read-less" v-show="person.description" v-if="person.readmore" @click="person.readmore = false">Weniger</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "team",
    props: ["block"],
    data() {
        return {
            readmore: false,
        };
    },
};
</script>
