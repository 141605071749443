<template>
    <div v-if="images" ref="slider" class="image-carousel keen-slider">
        <div v-for="(image, index) in images" class="keen-slider__slide" :key="'slide-'+index">
            <figure class="image-wrapper">
                <img :src="image.srcs.small.src" :width="image.srcs.small.width" :height="image.srcs.small.height" :alt="image.title" class="img-fluid" :class="'img-size-'+ imageSize" :srcset="[image.srcs.small.src+' '+image.srcs.small.width+'w', image.srcs.large.src+' '+image.srcs.large.width+'w']" :sizes="['(min-width: 576px) '+image.srcs.large.width+'w', '100vw']">
            </figure>
            <div v-if="image.caption" class="image-caption" v-html="image.caption"></div>
        </div>
        <button @click="slider.prev()" :class="['arrow', {'arrow-left': true, 'arrow-disabled': current === 0}]">Prev</button>
        <button v-if="slider" @click="slider.next()" :class="['arrow', {'arrow-right': true, 'arrow-disabled': current === slider.details().size - 1}]">Next</button>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider'

export default {
    name: 'ImageCarousel',
    props: ['images', 'index'],
    data () {
        return {
            current: 5,
        };
    },
    mounted() {
        this.current = this.index;
        this.slider = new KeenSlider(this.$refs.slider, {
            initial: this.current,
            slideChanged: (s) => {
                this.current = s.details().relativeSlide
            },
        });
    },
    beforeUnmount() {
        if (this.slider) this.slider.destroy();
    },
    methods: {

    }
}
</script>
