function lerp(p, s, e) {
    return p * (e - s) + s;
}
function getPoint(path, pathN, i) {
    var p = path.pointPaths[pathN][i];
    if (!p.adjustedPoint) {
        p.adjustedPoint = {};
        p.adjustedPoint.x = (p.x - path.w / 2)
        p.adjustedPoint.y = (p.y - path.h / 2)
    }

    return p.adjustedPoint;
}
function drawPath(w, h, ctx, pathobject, pathN, startPer, endPer, startSize, endSize) {
    var path = pathobject;
    if (path) {
        ctx.save()
        ctx.translate(w / 2, h / 2)
        var points = path.pointPaths[pathN]
        if (points) {
            var startIndex = Math.floor(points.length * startPer)

            var endIndex = Math.floor(points.length * endPer)

            for (var i = startIndex; i < endIndex; i++) {
                var r = lerp((i - startIndex) / (endIndex - startIndex), startSize, endSize)
                var currPoint = getPoint(path, pathN, i)

                ctx.beginPath()
                ctx.moveTo(currPoint.x, currPoint.y)
                ctx.ellipse(currPoint.x, currPoint.y, r, r, 0, 0, 2 * Math.PI);
                ctx.fill()
            }
            ctx.restore()
        }
    }
}

class pathManager {
    constructor(pathURLS, breakPoints) {
        this.breakPoints = breakPoints;
        this.paths = [];
        pathURLS.forEach(url => {
            this.paths.push({
                url: url,
                path: false,
                loading: false
            })
        });
    }
    getPathN(w) {
        var pathN = -1
        for (var i = 0; i < this.breakPoints.length; i++) {
            if (w < this.breakPoints[i]) {
                pathN = i;
                break;
            }
        }
        if (!~pathN) {
            pathN = this.breakPoints.length;
        }
        if (pathN >= this.paths.length) {
            pathN = this.paths.length - 1
        }
        return pathN
    }
    async load(w) {
        var pathN = this.getPathN(w);
        if (this.paths[pathN].path == false && this.paths[pathN].loading == false) {
            this.paths[pathN].loading = true
            this.paths[pathN].path = await this.loadPath(this.paths[pathN].url)
        }
    }
    loadPath(url) {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => response.json())
                .then(json => {
                    resolve(json)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }
    getPath(w) {
        var pathN = this.getPathN(w);
        return this.paths[pathN].path;
    }
}
Math.ease = function (t) {
    return -1 / 2 * (Math.cos(Math.PI * Math.max(Math.min(1, t), 0)) - 1);
};

export { drawPath, pathManager, getPoint }