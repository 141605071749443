<template>
    <div class="block block-images-2-cols container-fluid">
        <div v-if="block.images" class="row images-row">
            <div v-for="(image, index) in block.images" :key="'image-'+index" class="image-col col-sm-6">
                <figure class="image-wrapper" :class="themeColor ? 'bg-themeColor' : null">
                    <img :src="image.srcs.small.src" :width="image.srcs.small.width" :height="image.srcs.small.height" :alt="image.title" class="img-fluid" :class="'img-size-'+ imageSize" :srcset="[image.srcs.small.src+' '+image.srcs.small.width+'w', image.srcs.medium.src+' '+image.srcs.medium.width+'w']" :sizes="['(min-width: 576px) '+image.srcs.medium.width+'w', '100vw']">
                </figure>
            </div>
            <div class="row caption-row">
                <div class="image-caption col-12" v-html="block.caption"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'images',
  props: ['block', 'themeColor'],
}
</script>
