<template>
    <div data-v-84109bb2="" class="container-fluid search-entry-header bg-white d-flex flex-column justify-content-center">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3">
                <form @submit="send" class="search-form">
                    <input type="text" v-model="s" :placeholder="$tc('search.fieldPlaceholder')" ref="s" />
                    <button type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.117" height="20.117" viewBox="0 0 20.117 20.117">
                            <g transform="translate(-1283 -15)">
                                <g transform="translate(1283 15)" fill="none" stroke="currentColor" stroke-width="1.8">
                                    <circle cx="8.086" cy="8.086" r="8.086" stroke="none" />
                                    <circle cx="8.086" cy="8.086" r="7.186" fill="none" />
                                </g>
                                <line x2="5.881" y2="5.881" transform="translate(1296.6 28.6)" fill="none" stroke="currentColor" stroke-width="1.8" />
                            </g>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    </div>
    <div class="entry-content container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3">
                <div v-if="searched" class="search-results">
                    <div class="results-label border-bottom">{{ results.length }} Ergebnisse für “{{ searched }}”</div>
                    <div v-if="results.length" class="search-result-items border-bottom">
                        <a class="search-result-item" v-for="result in results" v-bind:key="result" :href="$url(result.link)" :title="result.title">
                            <h6 class="result-type-label">{{ $tc('search.resultTypeLabels.' + result.type) }}</h6>
                            <h3 class="result-title" v-html="result.title"></h3>
                            <div v-if="result.leadText && result.leadText.length" class="result-text" v-html="result.leadText"></div>
                        </a>
                    </div>
                </div>
                <div v-if="!results.length" class="search-tips">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <h5 class="search-tips-label">{{ $tc('search.searchTipsLabels.quickLinks') }}</h5>
                            <ul class="quick-links">
                                <li v-for="(quickLink, index) in quickLinks" v-bind:key="index"><a :href="quickLink.link" v-html="quickLink.label"></a></li>
                            </ul>
                        </div>
                        <div v-if="journalTags.length" class="col-12 col-lg-6">
                            <h5 class="search-tips-label">{{ $tc('search.searchTipsLabels.tags') }}</h5>
                            <nav class="tag-category-nav row">
                                <router-link v-for="(item, index) in journalTags" :key="'filter-item-' + index" :to="{ name: 'JournalCategoryArchive', params: { categorySlug: item.slug, locale: $route.params.locale || '' } }" class="btn btn-square-rounded btn-square-white btn-square-white-outlined">{{ item.title }}</router-link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Search } from '@/http/Search';
import { i18n } from '../i18n';

export default {
    name: 'Search',
    components: {},
    data() {
        return {
            s: '',
            quickLinks: [],
            journalTags: [],
            results: [],
            prevRoute: null,
            searched: null,
        };
    },
    watch: {
        '$route.params': {
            handler(newValue) {
                this.s = newValue.search;
                if (this.s) {
                    this.showResults();
                } else {
                    this.results = [];
                    //this.$refs.search.classList.remove('show-results');
                }
            },
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from.name || null;
        });
    },
    mounted() {
        var _this = this;
        this.getQuickLinks();
        this.getJournalTags();
        this.s = this.$route.params.search;
        if (this.s) {
            this.showResults();
        } else {
            setTimeout(function () {
                _this.$refs.s.focus();
            }, 500);
        }
        this.searched = this.s;
        this.initPage();
    },
    activated() {
        this.initPage();
    },
    methods: {
        send(e) {
            e.preventDefault();
            this.$push(`suche/${this.s}`);
        },
        async showResults() {
            // this.$refs.search.classList.add('show-results');
            try {
                var { data } = await Search.get(this.s);
                this.results = data.data.filter((result) => result.sectionId != 7);
                this.searched = this.s;
            } catch {
                //
            }
        },
        async getQuickLinks() {
            try {
                var { data } = await Search.getQuickLinks();
                this.quickLinks = data;
            } catch {
                //
            }
        },
        async getJournalTags() {
            try {
                var { data } = await Search.getTagCategories();
                this.journalTags = data;
            } catch {
                //
            }
        },
        closeSearch() {
            if (this.prevRoute) {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: 'JournalOverview', params: { locale: this.$route.params.locale || '' } });
            }
        },
        initPage() {
            this.$store.commit('view/setPageClass', 'no-page-header');
            this.$store.commit('navigation/setPageRoot', {
                title: this.$tc('search.searchHeading'),
                path: (i18n.global.locale != 'de' ? '/' + i18n.global.locale : '') + '/' + this.$tc('routePaths.search'),
            });
            this.setLangNav();
        },
        setLangNav() {
            const availableLanguageLinks = [
                { 'lang': 'de', 'path': '/' + this.$tc('routePaths.search', {}, 'de'), 'autoTranslated': false },
                { 'lang': 'fr', 'path': '/fr/' + this.$tc('routePaths.search', {}, 'fr'), 'autoTranslated': true },
                { 'lang': 'en', 'path': '/en/' + this.$tc('routePaths.search', {}, 'en'), 'autoTranslated': true }
            ];
            this.$store.commit('navigation/setLangNav', availableLanguageLinks.sort((a, b) => a.lang == i18n.global.locale ? -1 : 1));
        }
    },
};
</script>
