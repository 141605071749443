<template>
    <footer v-if="globalSiteData" class="site-footer text-sm container-fluid" :class="$route.name === 'JournalEntry' || ($route.name === 'JournalOverview' && 'bg-black')">
        <div class="row footer-row">
            <div class="footer-col footer-teaser-col col-12 col-md-6 col-lg-6 order-md-1 order-lg-0 border-left-md-only">
                <div v-if="globalSiteData.footerBlockOne" v-html="globalSiteData.footerBlockOne" class="footer-block-one d-flex flex-column h-100"></div>
            </div>
            <div class="footer-col-wrapper col-12 col-md-6 col-lg-6 order-lg-0">
                <div class="row">
                    <div class="footer-col footer-text-col col-12 col-lg-6 order-md-1 order-lg-0 border-left-lg-up border-top-lg-down">
                        <div v-if="globalSiteData.footerBlockTwo" class="footer-block-two">
                            <a class="sitemap-anchor" :id="$tc('siteFooter.hashAnchors.sitemap')" />
                            <div class="mobile-sitemap-dropdown-header">
                                <button type="button" class="mobile-sitemap-dropdown-toggle-btn btn-plain-text" @click="toggleMobileSitemapDropdown" :class="{expanded: showMobileSitemapDropdown}">
                                    <span v-if="showMobileSitemapDropdown" v-html="$tc('siteFooter.sitemapToggleBtn.hide')"></span>
                                    <span v-else v-html="$tc('siteFooter.sitemapToggleBtn.show')"></span>
                                </button>
                            </div>
                        </div>
                        <div class="footer-block-two mobile-sitemap-dropdown" :class="{'show': showMobileSitemapDropdown}" :style="{ maxHeight: mobileSitemapDropdownMaxH }">
                            <div class="mobile-sitemap-dropdown-inner" v-html="globalSiteData.footerBlockTwo"></div>
                        </div>
                    </div>
                    <div class="footer-col footer-text-col col-12 col-lg-6 border-left-lg-up border-top-md-down">
                        <div v-if="globalSiteData.footerBlockThree" v-html="globalSiteData.footerBlockThree" class="footer-block-three col-12 col-lg-6"></div>
                    </div>
                    <div class="footer-animation-col footer-col col-12 col-md-6 col-lg-3 order-md-1 d-flex ml-md-auto border-top-md-down">
                        <Vue3Lottie :animationData="animation" :loop="true" :autoPlay="true" height="auto" width="100%" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import lineFooter from '../assets/animations/line_footer.json';
import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';
export default {
    name: 'SiteFooter',
    components: {
        Vue3Lottie,
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            animation: lineFooter,
            showMobileSitemapDropdown: false,
            mobileSitemapDropdownMaxH: null,
            mobileSitemapDropdownT: false,
        };
    },
    computed: {
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    methods: {
        toggleMobileSitemapDropdown() {
            if (this.mobileSitemapDropdownT) {
                clearTimeout(this.mobileSitemapDropdownT);
            }
            this.mobileSitemapDropdownMaxH = '100vh';
            const timeoutD = this.showMobileSitemapDropdown ? 0 : 600;
            this.showMobileSitemapDropdown = !this.showMobileSitemapDropdown;
            const that = this;
            this.mobileSitemapDropdownT = setTimeout(function () {
                that.mobileSitemapDropdownMaxH = null;
            }, timeoutD);

        },
        footerOnHashChange(e) {
            const hash = location.hash;
            if (hash == '#'+this.$tc('siteFooter.hashAnchors.sitemap')) {
                if (!this.showMobileSitemapDropdown) {
                    this.toggleMobileSitemapDropdown();
                }
            }
        }
    },
    mounted() {
        window.addEventListener('hashchange', this.footerOnHashChange);
    },
    updated() {
        if(this.globalSiteData) {
            this.$nextTick(() => {
                this.initInternalLinks(this.$el);
            });
        }
    },
    unmounted() {
        window.removeEventListener('hashchange', this.footerOnHashChange);
    },
};
</script>
