<template>
    <div class="block block-accordeon">
        <div class="accordion">
            <div v-for="element in block.elements" v-bind:key="element" class="accordion-item">
                <component @click="element.isShow = !element.isShow" :is="element.type" :block="element"></component>
                <div class="accordion-collapse collapse" :class="{ show: element.isShow }">
                    <div class="accordion-body">
                        <template v-for="content in element.contentBlocks" v-bind:key="content">
                            <component :is="content.type" :block="content"></component>
                        </template>
                        <div class="container-fluid accordion-body-footer">
                            <div class="row">
                                <div class="col-12 offset-lg-3 col-lg-6 border-bottom-1">
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src
import accordeonHeader from '@/components/contentBlocks/AccordeonHeader';
import imageSingle from '@/components/contentBlocks/ImageSingle';
import textStandard from '@/components/contentBlocks/TextStandard';
import videoNative from '@/components/contentBlocks/VideoNative';
import audioNative from '@/components/contentBlocks/AudioNative';

export default {
    name: 'accordeon',
    props: ['block'],
    components: {
        videoNative,
        textStandard,
        accordeonHeader,
        imageSingle,
        audioNative,
    },
};
</script>
