<template>
    <div class="container-fluid block block-spacer" :class="block.size || ''">
        
    </div>
</template>

<script>

export default {
  name: 'spacer',
  props: ['block'],
}
</script>
