<template>
    <div class="block block-relatedJournalTagCategories block-text-standard container-fluid" :class="block.styles || null">
        <div class="row content-block-row">
            <div class="col-12 col-md-9 mx-md-auto col-lg-6 offset-lg-3">
                <hr class="opacity-100">
                <div v-if="block.text" v-html="block.text"></div>
                <nav class="tag-category-nav row">
                    <router-link v-for="(item, index) in block.journalTagCategories" :key="'filter-item-' + index" :to="{ name: 'JournalCategoryArchive', params: { categorySlug: item.slug, locale: $route.params.locale || '' } }" class="btn btn-square-rounded btn-square-white btn-square-white-outlined">{{ item.title }}</router-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'text',
    props: ['block', 'themeColor'],
    mounted() {
    },
};
</script>
