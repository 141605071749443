import { getHttp } from './fetchApi';

export const FeauturedProject = {
    URL: '/project',

    /*getAll(){
        return getHttp(`${this.URL}.json`)
    },*/

    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    },
}
