<template>
    <div class="block block-info-box container-fluid">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-lg-6 bg-light-gray">
                <div class="info-box" v-html="block.text"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'infoBox',
    props: ['block'],
};
</script>
