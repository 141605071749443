<template>
    <div class="container-fluid block block-image-gallery">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-md-9 mx-md-auto mx-lg-0 col-lg-6">
                <div v-if="block.images.length" class="gallery-thumbnails row">
                    <div v-for="(image, index) in block.images" class="col-auto gallery-thumnail-col" :key="'image-' + index">
                        <figure class="image-wrapper" :class="themeColor ? 'bg-themeColor' : null" @click="initLightbox(index)">
                            <img :src="image.srcs.thumbnail.src" width="image.srcs.thumbnail.width" height="image.srcs.thumbnail.height" alt="image.title" class="gallery-thumnail" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <lightbox-overlay ref="lightbox">
            <image-carousel :images="block.images" :index="currentCarouselIndex"></image-carousel>
        </lightbox-overlay>
    </div>
</template>

<script>
//import "keen-slider/keen-slider.min.css";
import LightboxOverlay from '@/components/LightboxOverlay';
import ImageCarousel from '@/components/ImageCarousel';

export default {
    name: 'imageGallery',
    components: {
        LightboxOverlay,
        ImageCarousel,
    },
    props: ['block', 'themeColor'],
    data() {
        return {
            currentCarouselIndex: 0,
        };
    },
    methods: {
        initLightbox(imgIndex) {
            this.currentCarouselIndex = imgIndex;
            this.$refs.lightbox.openLightbox();
        },
    },
    mounted() {},
    beforeUnmount() {},
};
</script>
