import axios from 'axios';
import { i18n } from '../i18n'

export const Newsletter = {
    URL: 'https://c293ccca.sibforms.com/serve/MUIEAEhBZQn96HpvYWLbN9xeqPFpfhKbzwwoyw7Mzv4WOO6eQ-Nf_dFvI83-kFFRLWof_AmVor-sUjMJQtepIZSEksHF1WzKyLJXOSKTINSczhKkctiqvdCDbFg28U9kvrKUtMry20pz0HOwc4AZPkomHZY2FeybVZBtTeUOZ5rEYVOcbPfv9cPnGKevQLo-xwsmLXd5xilvOeHv?isAjax=1',

    send(email){
        var formData = new FormData();
        formData.append('EMAIL', email);
        formData.append('locale', i18n.global.locale);

        return axios.post(this.URL,
            formData
        );
    },

}
