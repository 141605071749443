<template>
    <transition name="fade">
        <div v-if="isOpen" class="lightbox-overlay">
            <button class="lightbox-overlay-close-btn" @click="closeLightbox">Schliessen</button>
            <div class="lightbox-inner">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LightboxOverlay',
    props: ['block'],
    data() {
        return {
            isOpen: false,
        };
    },
    mounted() {},
    methods: {
        openLightbox() {
            this.isOpen = true;
            document.body.classList.add('lightbox-overlay-open');
        },
        closeLightbox() {
            this.isOpen = false;
            document.body.classList.remove('lightbox-overlay-open');
        },
    },
};
</script>
