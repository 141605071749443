<template>
    <div class="block block-accordion-header container-fluid" :class="block.styles || null">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-md-9 mx-md-auto mx-lg-0 col-lg-6">
                <h2 class="accordion-header">
                    <p class="text-sm accordion-heading" v-if="block.topic">{{ block.topic }}</p>
                    <button class="accordion-button" :class="{ collapsed: collapsed, 'text-lg': block.style, 'text-base': !block.style }" @click="collapsed = !collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        <div class="flex flex-col items-center justify-center">
                            <div class="text-base" v-if="block.subHeading">{{ block.subHeading }}</div>

                            {{ block.heading }}
                        </div>
                    </button>
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'accordeonHeader',
    props: ['block'],
    data() {
        return {
            collapsed: true,
        };
    },
};
</script>
