<template>
    <div v-if="block.links" class="block block-button-links container-fluid">
        <div class="row content-block-row">
            <nav class="col-12 d-flex flex-column align-items-center">
                <a v-for="(link, index) in block.links" :key="'link-' + index" class="button-link btn" :href="link.url" :target="link.target"><span v-html="link.label" /> <svg xmlns="http://www.w3.org/2000/svg" width="18.093" height="15.899" viewBox="0 0 18.093 15.899"><g fill="none" stroke="currentColor" stroke-width="1.9"><path d="M0 8.04h16.973"/><path d="m9.15.686 7.571 7.264-7.571 7.264"/></g></svg></a>
            </nav>
        </div>
    </div>
</template>

<script>
import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';
export default {
    name: 'buttonLinks',
    mixins: [InternalLinkReplacementMixin],
    props: ['block', 'themeColor'],
    mounted() {
        this.$nextTick(() => {
            this.initInternalLinks(this.$el);
        });
    },
};
</script>
