<template>
    <div class="block block-media-teaser container-fluid" :class="block.asset ? 'block-image-single-' + block.asset.kind : null"  :style="{'--marqueeSlowDownAnimationDuration': marqueeSlowDownAnimationDuration}">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-md-9 mx-md-auto mx-lg-0 col-lg-6">
                <component :is="wrapperComponent.tag" :href="wrapperComponent.href" class="d-block media-teaser">
                    <template v-if="block.asset">

                        <img v-if="block.asset.kind == 'image'" :src="block.asset.src" :width="block.asset.width" :height="block.asset.height" :alt="block.asset.title" class="w-100 img-fluid img-size-medium" :srcset="!block.asset.srcs ? null : [block.asset.srcs.mobile.src + ' ' + block.asset.srcs.mobile.width + 'w', block.asset.srcs.desktop.src + ' ' + block.asset.srcs.desktop.width + 'w']" :sizes="!block.asset.srcs ? null : ['(min-width: 576px) ' + block.asset.srcs.desktop.width + 'w', '100vw']" />

                        <Vue3Lottie v-if="block.asset.kind == 'json'"
                        :animation-link="block.asset.src" />

                        <video v-if="block.asset.kind == 'video'" width="320" height="240" class="w-100 h-auto" autoplay loop muted playsinline>
                            <source :src="block.asset.src" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    </template>
                    <marquee-text v-if="block.text && block.text.length" class="teaser-text-marquee bg-black text-white" :repeat="marqueeRepeat" :duration="marqueeDuration" :paused="pauseMarquee" ref="marqueeComponent"><span class="marquee-text-line" v-html="block.text"></span></marquee-text>
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import MarqueeText from 'vue-marquee-text-component'; // see https://github.com/EvodiaAut/vue-marquee-text-component/tree/v1
export default {
    name: 'mediaTeaser',
    components: {
        Vue3Lottie,
        MarqueeText
    },
    props: ['block', 'themeColor'],
    data() {
        return {
            marqueeSpeed: 0.25, // Speed factor, effective animation duration is calculated based on text length, higher = slower
            marqueHoverSlowdownFactor: 2,
        };
    },
    computed: {
        wrapperComponent() {
            if (this.block.link && this.block.link.url.length) {
                return {
                    tag: 'a',
                    href: this.block.link.url
                };
            } else {
                return {
                    tag: 'div',
                    href: null
                };
            }
        },
        marqueeRepeat() {
            if (this.block.text && this.block.text.length) {
                return Math.ceil((90+(2*this.block.text.length))/this.block.text.length);
            } else {
                return 0;
            }
        },
        marqueeDuration() {
            if (this.block.text && this.block.text.length) {
                return this.block.text.length * this.marqueeSpeed;
            } else {
                return 0;
            }
        },
        marqueeSlowDownAnimationDuration() {
            this.slowMarqueeDuration
            return (this.marqueeDuration * this.marqueHoverSlowdownFactor) + 's';
        }
    },
};
</script>
