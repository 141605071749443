<template>
    <div v-if="entry.entryMeta || entry.entryTags" class="journal-entry-meta">
        <template v-if="entry.entryMeta">
            <div v-for="(entryMetaRow, index) in entry.entryMeta" :key="'entry-meta-' + index"  class="container-fluid entry-meta-container">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-3 mx-md-auto mx-lg-0">
                        <h4 class="entry-meta-heading" v-html="entryMetaRow.heading"></h4>
                    </div>
                    <div class="col-12 col-md-9 mx-md-auto mx-lg-0 col-lg-6" v-html="entryMetaRow.text">
                    </div>
                </div>
            </div>
        </template>
        <div v-if="entry.entryTags && entry.entryTags.length" class="container-fluid entry-meta-container">
            <div class="row">
                <div class="col-12 col-md-9 col-lg-3 mx-md-auto mx-lg-0">
                    <h4 class="entry-meta-heading">{{ $tc("journal.tags") }}</h4>
                </div>
                <div class="col-12 col-md-9 mx-md-auto mx-lg-0 col-lg-6">
                    <nav class="tag-category-nav row">
                        <router-link v-for="(item, index) in entry.entryTags" :key="'filter-item-' + index" :to="{ name: 'JournalCategoryArchive', params: { categorySlug: item.slug, locale: $route.params.locale || '' } }" class="btn btn-square-rounded btn-square-white btn-square-white-outlined">
                            {{ item.title }}
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "JournalEntryMeta",
    props: ["entry"],
    data() {},
    mounted() {},
};
</script>
