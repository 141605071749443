<template>
    <div class="container-fluid block block-quote" :class="block.backgroundColor === 'themeColor' && 'text-themeColor'">
        <div class="row content-block-row">
            <div class="text-center" :class="block.fullWidth || false ? 'col-12 col-lg-9' : 'col col-centered'">
                <blockquote v-html="block.text"></blockquote>
                <p v-if="block.author" class="quote-author">{{ block.author }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'text',
    props: {
        block: Object,
        themeColor: {
            type: String,
            default: 'default',
        },
    },
};
</script>
