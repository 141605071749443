<template>
    <div class="container-fluid podcast-container-outer">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-lg-4 offset-lg-1">
                <div v-if="block && block.src" class="block block-podcast">
                    <div class="row podcast-button-row">
                        <div class="col mx-auto podcast-button-col">
                            <button class="podcast-player-button" :class="['bg-' + (block.backgroundColor == 'black' ? themeColor : 'black'), 'text-' + (block.backgroundColor == 'black' ? 'black' : themeColor)]" @click="initLightbox" ref="playerBtn">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24"><path :fill="playerBtnIconColor" d="M25 12 0 24V0Z" data-name="Polygon 3" /></svg>
                                <span class="btn-label" v-html="block.title || $tc('podcast.defaultButtonLabel')"></span>
                            </button>
                        </div>
                    </div>
                    <lightbox-overlay ref="lightbox">
                        <div class="container-fluid podcast-container">
                            <div class="row content-block-row podcast-row">
                                <div class="col col-centered podcast-col">
                                    <iframe :src="block.src + '?theme=0'" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                                </div>
                            </div>
                        </div>
                    </lightbox-overlay>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import "keen-slider/keen-slider.min.css";
import LightboxOverlay from '@/components/LightboxOverlay';

export default {
    name: 'podcast',
    components: {
        LightboxOverlay,
    },
    props: ['block', 'themeColor'],
    data() {
        return {
            playerBtnIconColor: '#000',
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.setPlayerBtnIconColor();
        });
    },
    beforeUnmount() {},
    methods: {
        initLightbox(imgIndex) {
            this.$refs.lightbox.openLightbox();
        },
        setPlayerBtnIconColor() {
            if (this.block.backgroundColor != 'black') {
                this.playerBtnIconColor = window.getComputedStyle(this.$refs.playerBtn).getPropertyValue('color');
            }
        },
    },
};
</script>
