export default {
    data(){
        return {
            //internalLinksInited: false,
        }
    },
    methods: {
        initInternalLinks(context) {
            //if (context && !this.internalLinksInited) {
            if (context) {
                this.internalLinksInited = true;
                const entryContentLinks = context.getElementsByTagName('a');
                const apiLocation = new URL(process.env.VUE_APP_API_URL);
                const appLocation = window.location;
                for (var i = 0; i < entryContentLinks.length; i++) {
                    const link = entryContentLinks[i];
                    if (link.hostname == appLocation.hostname) {
                        link.addEventListener('click', this.onInternalLinkClicked);
                    } else if (link.hostname == apiLocation.hostname && this.isFolderPath(link.pathname)) {
                        link.href = link.href.replace(apiLocation.origin, appLocation.origin);
                        link.addEventListener('click', this.onInternalLinkClicked);
                    }
                }
                
            }
        },
        onInternalLinkClicked(e) {
            const eTarget = e.target.href ? e.target : e.target.closest('a');
            
            if (eTarget.getAttribute('target') !== '_blank') {
                e.preventDefault();
                const routeObj = {
                    path: eTarget.pathname
                }
                // if (eTarget.search) {
                //     
                // } else {
                    
                // }
                
                if (eTarget.hash) {
                    if (eTarget.pathname == window.location.pathname) {
                        const anchorEl = document.getElementById(eTarget.hash.split('#')[1]);
                        if (anchorEl) {
                            const offsetEl = document.getElementById('site-header-navbar');
                            const scrollOffset = anchorEl.getBoundingClientRect().top + (window.scrollY || window.pageYOffset) - (offsetEl ? offsetEl.clientHeight : 0);
                            window.scroll({
                                top: scrollOffset, 
                                left: 0, 
                                behavior: 'smooth' 
                            });
                            if (eTarget.hash != window.location.hash) {
                                history.pushState(null, null, eTarget.hash);
                            }
                        }
                    } else {
                        routeObj['hash'] = eTarget.hash;
                        this.$router.push(routeObj);
                    }
                } else {
                    this.$router.push(routeObj);
                }
            }
        },
        isFolderPath(pathname) {
            return pathname.split('/').pop().indexOf('.') < 0;
        }
    }
}
