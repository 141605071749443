import { createStore } from 'vuex'

//modules
import { general } from './general'
import { navigation } from './navigation'
//import { journalCategories } from './journalCategories'
import { view } from './view'


export default createStore({
  modules: {
    general,
    navigation,
    //journalCategories,
    view
  }
})
