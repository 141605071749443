<template>
    <div class="block block-text-standard container-fluid">
        <div class="row content-block-row">
            <div v-if="!(!block.sectionHeading && block.fullWidth)" class="col-12" :class="block.fullWidth ? '' : 'col-lg-3'">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12" :class="block.fullWidth ? '' : 'col-md-9 mx-md-auto mx-lg-0 col-lg-6'" v-html="block.text" ref="textColumn"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'text',
    props: ['block', 'themeColor'],
    mounted() {
        this.$nextTick(() => {
            if (this.$el.previousElementSibling) {
                var currentBgClass = this.block.backgroundColor !== '' ? 'bg-' + this.block.backgroundColor : false;
                if (((!currentBgClass || currentBgClass == 'bg-null') && !this.$el.previousElementSibling.classList.contains('bg-')) || (currentBgClass && this.$el.previousElementSibling.classList.contains(currentBgClass))) {
                    var firstTextElTagName = this.$refs.textColumn.firstElementChild.tagName.toLowerCase();
                    if (firstTextElTagName == 'h2') {
                        this.$el.classList.add('text-starts-with-h2');
                    } else if (firstTextElTagName == 'h3') {
                        this.$el.classList.add('text-starts-with-h3');
                    } else if (firstTextElTagName == 'h4') {
                        this.$el.classList.add('text-starts-with-h4');
                    }
                }
            }
        });
    },
};
</script>
