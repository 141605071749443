<template>
    <div class="container-fluid">
        <div class="row content-block-row">
            <div :class="block.fullWidth || false ? 'col-12' : 'col col-centered'">
                <div :id="'block-iframe-'+block.id" class="block block-iframe" ref="iframeBlock">
                    <div v-if="block.src" class="iframe-row">
                        <iframe :src="block.src" :style="{width:'100%', height: height }"></iframe>
                    </div>
                    <div v-if="block.caption" class="row caption-row">
                        <div class="image-caption col-12" v-html="block.caption"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'iframe',
    props: ['block', 'themeColor'],
    data() {
        return {
            height: 0,
            resizeT: false,
        }
    },
    mounted() {
        this.setHeight();
        window.addEventListener("resize", this.onWindowResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            if (this.resizeT) {
                clearTimeout(this.resizeT);
            }
            const that = this;
              this.resizeT = setTimeout(function () {
                  that.setHeight();
              }, 100);
          },
        setHeight() {
            var width = this.$refs.iframeBlock.clientWidth;
            if (window.innerWidth <= (this.block.breakpointMobile || 0) && this.block.widthMobile && this.block.heightMobile) {
                this.height = Math.ceil(width * this.block.heightMobile/this.block.widthMobile) +'px';
            } else {
                this.height = (this.block.width && this.block.height ? Math.ceil(width * this.block.height/this.block.width) : 0 )+'px';
            }
        }
    }
}
</script>
