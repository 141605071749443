import { General } from '@/http/General'

export const general = {
    namespaced: true,
    state: {
        data: null,
    },
    getters: {
        get: state => {
            return state.data
        },

    },
    mutations: {
        set: (state, data) => {
            state.data = data;
        },
    },
    actions: {
        async fetch({ commit }){
            const { data } = await General.get();
            commit('set', data);
        },
    },
}
