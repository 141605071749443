<template>
    <div class="entry-header page-header page-header-multimedia-header d-flex flex-column justify-content-center align-items-center">
        <figure v-if="entry && entry.headerMedia" class="entry-header-media h-100 w-100">
            <Vue3Lottie v-if="entry.headerMedia.type == 'lottie'" :animationLink="entry.headerMedia.src" height="100%" width="100%" :rendererSettings="{ preserveAspectRatio: 'xMidYMid slice' }" />
            <video v-else-if="entry.headerMedia.type == 'video'" class="entry-header-video" autoplay muted loop playsinline>
                <source :src="entry.headerMedia.src" :type="entry.headerMedia.mime" />
            </video>
        </figure>
        <div v-if="entry && entry.headerText && entry.headerText.length" class="container-fluid entry-header-text text-heading-md" :class="[entry.theme ? entry.theme.headerTextStyle : null, 'position-' + (hasStickyHeaderText && headerTextIsSticky ? 'fixed' : 'absolute'), { 'sticky-entry-header': hasStickyHeaderText }]" ref="headerText">
            <h1 class="entry-title" v-html="entry.headerText"></h1>
        </div>
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie';
export default {
    name: 'HeaderMultimediaHeader',
    components: {
        Vue3Lottie,
    },
    props: {
        entry: null,
    },
    data() {
        return {
            gradientObserver: null,
            gradientObserverTargetEl: null,
            headerTextIsSticky: false,
            hasStickyHeaderText: false,
            stickyHeaderTextFollowUpEl: null,
        };
    },
    computed: {},
    mounted() {
        this.initColorGradient();
        this.initStickyHeaderText();
    },
    activated() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            this.gradientObserver.observe(this.gradientObserverTargetEl);
        }
        this.initStickyHeaderText();
        this.autoplayVideos();
    },
    unmounted() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            this.gradientObserver.unobserve(this.gradientObserverTargetEl);
        }
        this.destroyStickyHeaderText();
    },
    deactivated() {
        if (this.gradientObserverTargetEl && this.gradientObserver) {
            this.gradientObserver.unobserve(this.gradientObserverTargetEl);
        }
        this.destroyStickyHeaderText();
    },
    methods: {
        autoplayVideos() {
            const videos = document.querySelectorAll('.entry-header video');
            videos.forEach((video) => {
                video.currentTime = 0;
                video.play();
            });
        },
        initColorGradient() {
            if (this.entry && this.entry.theme.headerColorGradient) {
                var blockElements = document.querySelectorAll('.entry-content .block:not(.bg-themeColor)');
                if (blockElements.length) {
                    this.gradientObserverTargetEl = document.createElement('div');
                    this.gradientObserverTargetEl.classList.add('page-header-color-gradient');
                    blockElements[0].parentNode.insertBefore(this.gradientObserverTargetEl, blockElements[0]);
                    this.gradientObserver = new IntersectionObserver(this.gradientObserverCallback);
                    this.gradientObserver.observe(this.gradientObserverTargetEl);
                }
            }
        },
        gradientObserverCallback(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.boundingClientRect.top > 0) {
                    this.gradientObserverTargetEl.classList.add('scrolling-gradient');
                } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
                    this.gradientObserverTargetEl.classList.remove('scrolling-gradient');
                }
            });
        },
        initStickyHeaderText() {
            if (this.entry && this.entry.theme.stickyHeaderText && this.$refs.headerText) {
                this.stickyHeaderTextFollowUpEl = this.$el.nextSibling;
                document.addEventListener('scroll', this.onStickyHeaderTextDocumentScroll);
                document.dispatchEvent(new Event('scroll'));
                this.hasStickyHeaderText = true;
            }
        },
        destroyStickyHeaderText() {
            if (this.entry && this.entry.theme.stickyHeaderText && this.$refs.headerText) {
                document.removeEventListener('scroll', this.onStickyHeaderTextDocumentScroll);
            }
        },
        onStickyHeaderTextDocumentScroll(e) {
            const elStyle = window.getComputedStyle(this.$el);
            this.headerTextIsSticky = this.stickyHeaderTextFollowUpEl.getBoundingClientRect().top >= (this.$el.clientHeight + this.$refs.headerText.clientHeight + parseFloat(window.getComputedStyle(this.$el).paddingTop)) / 2;
        },
    },
};
</script>
<style scoped>
.entry-header-text.sticky-entry-header {
    padding-top: 20px;
    padding-bottom: 20px;
}
.entry-header-text.sticky-entry-header.position-absolute {
    bottom: 0;
}
</style>
