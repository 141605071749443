<template :key="categorySlug || 'default'">
    <div class="journal-overview" :key="categorySlug || 'default'" :class="{'start-animation-showing': view.showJournalStartAnimation}">
        <journal-start-animation v-if="view.showJournalStartAnimation" :titles="globalSiteData && globalSiteData.animationTexts" />
        <div class="entry-content data-container" :class="{'loading-data': !data}">
            <journal-preview-blocks :entries="data" :limit="limit" :class="{'has-load-more': (showLoadMore && data.length > limit)}" :animated-masonry-transition="view.showJournalStartAnimation ? false : true"></journal-preview-blocks>
            <div v-if="showLoadMore && data.length > limit" class="journal-load-more" @click="limit = limit + 12">
                mehr laden<br />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.045 17.612">
                    <g fill="none" stroke="currentColor" stroke-width="1.4" data-name="Group 4457" opacity="1">
                        <path d="M8.523 0v16.622" data-name="Line 2552" />
                        <path d="m.494 8.594 8.027 8.028 8.029-8.028" data-name="Path 2864" />
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '../i18n'
import { Journal } from '@/http/Journal';
import JournalPreviewBlocks from '@/components/journal/JournalPreviewBlocks';
import JournalStartAnimation from '@/components/JournalStartAnimation';

export default {
    name: 'JournalOverview',
    components: {
        JournalPreviewBlocks,
        JournalStartAnimation,
    },
    props: {
        categorySlug: {
            default: false,
        },
    },
    data() {
        return {
            data: null,
            //stampEntriesData: null,
            metaData: null,
            limit: 12,
            showLoadMore: false,
        };
    },
    computed: {
        ...mapGetters({
            'view':'view/get',
        }),
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
    },
    mounted() {
        this.initPageContent();
        this.getJournalEntries();
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        this.initPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', this.categorySlug);
        if (this.metaData) {
            this.$setHeaderData(this.metaData);
        }
        this.$store.commit('navigation/setPageRoot', {
            title: 'Journal',
            path: i18n.global.locale != 'de' ? "/" + i18n.global.locale : ''
        });
        this.setLangNav();
    },
    deactivated() {
        this.resetPageContent();
        //this.$store.commit('journalCategories/setCurrentCategorySlug', false);
    },
    methods: {
        async getJournalEntries() {
            try {
                let response;
                // if (this.categorySlug) {
                //     response = await Journal.getEntriesByTaxonomy('category', 'journalTagCategories', this.categorySlug);
                // } else {
                    //await this.getJournalStampEntries();
                    response = await Journal.getAll();
                // }

                this.data = response.data.data;
                this.metaData = response.data.meta;
                // set header data
                this.$setHeaderData(this.metaData);
                this.showLoadMore = true;
            } catch {
                /**/
            }
        },
        /*async getJournalStampEntries() {
            try {
                let response;
                response = await Journal.getAllJournalEvents();

                this.stampEntriesData = response.data.data;
            } catch {
                //
            }
        },*/
        initPageContent() {
            this.$store.commit('view/setPageClass', 'theme-dark');
        },
        resetPageContent() {
            //document.body.classList.remove('theme-dark');
        },
        setLangNav() {
            const availableLanguageLinks = [
                { 'lang': 'de', 'path': '/', 'autoTranslated': false },
                { 'lang': 'fr', 'path': '/fr/', 'autoTranslated': true },
                { 'lang': 'en', 'path': '/en/', 'autoTranslated': true }
            ];
            this.$store.commit('navigation/setLangNav', availableLanguageLinks.sort((a, b) => a.lang == i18n.global.locale ? -1 : 1));
        }
    },
};
</script>
