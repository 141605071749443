<template>
    <div class="block block-youtube-video container-fluid" :class="'block-youtube-video-'+imageSize">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{block.sectionHeading}}</h2>
            </div>
            <div class="col-12" :class="imageSize == 'large' ? 'col-lg-6' : 'col-lg-4 offset-lg-1'">
                <figure class="ratio image-wrapper" :class="['ratio-'+(block.ratio ? block.ratio.value : '16x9'), themeColor ? 'bg-themeColor' : null]" @click="initLightbox" ref="backgroundColorRef">
                    <img v-if="block.previewImage && imageSrc" class="img-fluid"
                    :class="'img-size-'+ imageSize"
                    :src="mobileImageSrc.src"
                    :width="imageSrc.width"
                    :height="imageSrc.height"
                    :alt="block.previewImage.title"
                    :srcset="[mobileImageSrc.src+' '+mobileImageSrc.width+'w', imageSrc.src+' '+imageSrc.width+'w']"
                    :sizes="['(min-width: 576px) '+imageSrc.width+'w', '100vw']">
                    <div v-if="block.videoType == 'youtube' && globalSiteData && globalSiteData.privacyYoutube" class="privacy-text">
                        <div v-html="globalSiteData.privacyYoutube"></div>
                    </div>
                    <div v-if="block.videoType == 'vimeo' && globalSiteData.privacyVimeo" class="privacy-text">
                        <div v-html="globalSiteData.privacyVimeo"></div>
                    </div>
                    <button class="play-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96"><g data-name="Gruppe 4685" transform="translate(-635 -6940)"><circle cx="48" cy="48" r="48" fill="#000" data-name="Ellipse 380" transform="translate(635 6940)"/><path fill="#fff" d="m702 6988-33 16v-32Z" data-name="Polygon 1"/></g></svg>
                        <!--
                        <svg xmlns="http://www.w3.org/2000/svg" width="161" height="161" viewBox="0 0 161 161">
                            <path d="M80.5,161a81.093,81.093,0,0,1-16.223-1.635,80.058,80.058,0,0,1-28.785-12.113A80.736,80.736,0,0,1,6.326,111.834,80.1,80.1,0,0,1,1.635,96.723a81.284,81.284,0,0,1,0-32.447A80.059,80.059,0,0,1,13.748,35.492,80.736,80.736,0,0,1,49.166,6.326,80.092,80.092,0,0,1,64.276,1.635a81.284,81.284,0,0,1,32.447,0,80.061,80.061,0,0,1,28.785,12.113,80.736,80.736,0,0,1,29.166,35.418,80.092,80.092,0,0,1,4.691,15.111,81.284,81.284,0,0,1,0,32.447,80.059,80.059,0,0,1-12.113,28.785,80.736,80.736,0,0,1-35.418,29.166,80.094,80.094,0,0,1-15.111,4.691A81.1,81.1,0,0,1,80.5,161ZM60,50v61l51-30.5Z" :fill="playBtnIconColor" opacity="0.798"/>
                        </svg>-->
                    </button>
                </figure>
            </div>
        </div>
        <lightbox-overlay ref="lightbox">
            <div class="container-fluid video-container">
                <div class="row content-block-row video-row">
                    <div class="col-12 video-col">
                        <div class="ratio video-wrapper" :class="'ratio-'+(block.ratio ? block.ratio.value : '16x9')">
                            <iframe v-if="block.videoType == 'youtube'" width="560" height="315" :src="'https://www.youtube-nocookie.com/embed/'+block.videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <iframe v-if="block.videoType == 'vimeo'" width="560" height="315" :src="'https://player.vimeo.com/video/'+block.videoId+'?autoplay=1&dnt=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <video v-if="block.videoType == 'internal'" width="560" height="315" controls autoplay>
                                <source :src="block.internalVideo" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </lightbox-overlay>
        </div>
    </template>

    <script>
    //import "keen-slider/keen-slider.min.css";
    import LightboxOverlay from '@/components/LightboxOverlay';

    export default {
        name: 'youtubeVideo',
        components: {
            LightboxOverlay,
        },
        props: ['block', 'themeColor'],
        data(){
            return {
                //playBtnIconColor: '#000',
                defaultImageSize : 'large',
                mobileImageSize: 'small',
                accept: false
            }
        },
        computed: {
            imageSize() {
                return this.block.previewSize ? this.block.previewSize : this.defaultImageSize;
            },
            imageSrc() {
                return this.block.previewImage && this.block.previewImage.srcs ? (this.block.previewImage.srcs[this.imageSize] || null) : null;
            },
            mobileImageSrc() {
                return this.block.previewImage && this.block.previewImage.srcs ? (this.block.previewImage.srcs[this.mobileImageSize] || null) : null;
            },
            globalSiteData () {
                return this.$store.getters['general/get']
            }
        },
        mounted() {
            /*this.$nextTick(() => {
                this.setPlayBtnIconColor()
            });*/
        },
        beforeUnmount() {

        },
        methods: {
            initLightbox(imgIndex) {
                this.$refs.lightbox.openLightbox();
            },
            /*setPlayBtnIconColor() {
                if (this.block.previewImage) {
                    this.playBtnIconColor = window.getComputedStyle(this.$refs.backgroundColorRef).getPropertyValue('background-color');
                }
            }*/
        },
    }
    </script>
