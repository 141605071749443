<template>
    <div class="foundation static-foundation-page-content">
        <div class="container-fluid">
            <div class="content-blocks" ref="entryContent">
                <div class="row">
                    <div class="col-12 col-sm-6 offset-sm-6">
                        <div class="block block-text-standard">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-12 col-sm-6 offset-sm-6">
                    <site-footer></site-footer>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
//import SiteFooter from '@/components/SiteFooter';
export default {
  name: 'StaticFoundationPageContent',
  components: {
      //SiteFooter
  }
}
</script>
