import { getHttp } from './fetchApi';

export const Foundation = {
    URL: '/foundation',
    
    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    },

    getNavigation(){
        return getHttp(`${this.URL}/navigation.json`)
    }

}
