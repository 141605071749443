<template>
    <div class="container-fluid content-blocks pt-no-header">
        <div class="not-found-content text-md text-center">
            <template v-if="$i18n.locale == 'fr'">
                <p>Malheureusement, nous n'avons pas trouvé cette page.</p>
                <p>La page que vous avez consultée n'existe pas, a été modifiée ou a changé de nom. Veuillez utiliser notre <router-link :to="$tc('routePaths.search')">recherche</router-link> pour trouver le contenu souhaité.</p>
                <p>Par ailleurs, nous donnons régulièrement un aperçu de notre travail dans notre <router-link :to="{ name: 'JournalOverview', params: {locale: $route.params.locale || ''} }">journal</router-link>. N'hésitez pas à y jeter un coup d'œil!</p>
            </template>
            <template v-else-if="$i18n.locale == 'en'">
                <p>Unfortunately we have not found the page.</p>
                <p>The page you have called up does not exist, has been changed or renamed. Please use our <router-link :to="$tc('routePaths.search')">search</router-link> to find the content you are looking for.</p>
                <p>By the way: We regularly provide insights into our work in our <router-link :to="{ name: 'JournalOverview', params: {locale: $route.params.locale || ''} }">journal</router-link>. Take a look!</p>
            </template>
            <template v-else>
                <p>Leider haben wir die Seite nicht gefunden.</p>
                <p>Die von Ihnen aufgerufene Seite existiert nicht, wurde verändert oder umbenannt. Bitte nutzen Sie unsere <router-link :to="$tc('routePaths.search')">Suche</router-link>, um den gewünschten Inhalt zu finden.</p>
                <p>Übrigens: In unserem <router-link :to="{ name: 'JournalOverview', params: {locale: $route.params.locale || ''} }">Journal</router-link> geben wir regelmässig Einblicke in unsere Arbeit. Schauen Sie mal vorbei!</p>
            </template>
        </div>
    </div>
</template>

<script>
import { i18n } from '@/i18n'
export default {
  name: 'NotFound',
}
</script>
