<template>
    <div class="container-fluid block block-person">
        <div class="row content-block-row">
            <div :class="block.fullWidth || false ? 'col-12' : 'col col-centered'">
                <div class="row">
                      <div v-if="block.image" class="col-4 col-sm-2 mx-auto mx-sm-0" :class="themeColor ? 'bg-'+themeColor : null">
                          <img class="person-image" :src="block.image.src" :alt="block.image.title" :width="block.image.width" :height="block.image.height">
                      </div>
                      <div class="col-sm-10 person-text text-sm d-flex flex-row align-items-center" v-html="block.text"></div>
                  </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'person',
  props: ['block'],
}
</script>
