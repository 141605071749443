<template>
    <div class="container-fluid entry-header content-blocks content-blocks-centered journal-entry-header d-flex flex-column">
        <div class="text-heading-md position-absolute animation-heading">
            <h1 class="entry-title" v-html="entry.title"></h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JournalEntryHeader',
    components: {},
    props: {
        entry: {
            default: null,
        },
    },
};
</script>
