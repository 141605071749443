<template>
    <a class="block-link-anchor" :id="block.id" />
</template>

<script>

export default {
    name: 'linkAnchor',
    props: ['block'],
};
</script>
