<template>
    <div class="main-navigation-overlay-inner d-flex flex-column">
        <nav class="main-navigation">
            <ul class="ul-level-0">
                <template v-for="(mainElement, index) in menu" :key="index">
                    <li v-if="mainElement.routerLink || mainElement.href">
                        <router-link v-if="mainElement.routerLink" :to="$url(mainElement.routerLink)" :title="mainElement.title" @click="onNavLinkClicked" v-html="mainElement.title" />
                        <a v-else :href="mainElement.href" :title="mainElement.title" @click="onNavLinkClicked" v-html="mainElement.title" />
                    </li>
                </template>
            </ul>
        </nav>
        <nav class="secondary-navigation row mt-auto align-items-end">
            <ul class="col12 d-flex meta-navigation">
                <template v-for="(metaNavItem, index) in metaMenu" :key="index">
                    <li v-if="metaNavItem.routerLink || metaNavItem.href">
                        <router-link v-if="metaNavItem.routerLink" :to="$url(metaNavItem.routerLink)" :title="metaNavItem.title" @click="onNavLinkClicked" v-html="metaNavItem.title" />
                        <a v-else :href="metaNavItem.href" :title="metaNavItem.title" @click="onNavLinkClicked" v-html="metaNavItem.title" />
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MainNavigation',
    props: [],
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            menu: 'navigation/getMenu',
            metaMenu: 'navigation/getMetaMenu',
            pageRoot: 'navigation/getPageRoot',
        }),
    },
    methods: {
        onNavLinkClicked(e) {
            const hash = e.target.href.split('#')[1] || null;
            if (hash) {
                e.preventDefault();
                const anchorEl = document.getElementById(hash);
                if (anchorEl) {
                    const offsetEl = document.getElementById('site-header-navbar');
                    const scrollOffset = anchorEl.getBoundingClientRect().top + (window.scrollY || window.pageYOffset) - (offsetEl ? offsetEl.clientHeight : 0);
                    window.scroll({
                        top: scrollOffset,
                        left: 0,
                        behavior: 'smooth',
                    });
                    history.pushState(null, null, '#' + hash);
                    window.dispatchEvent(new Event('hashchange'));
                }
            }

            this.$emit('closeMenu');
        },
        // onDropDownLinkClicked(e, index) {
        //     e.preventDefault();
        //     this.$store.commit('navigation/setMenuItemActiveByIndex', index);
        // },
        isActiveNavItem(navItem) {
            return this.pageRoot && this.pageRoot.path != null && navItem.routerLink && this.pageRoot.path.replace(/^\/|\/$/g, '') == navItem.routerLink.replace(/^\/|\/$/g, '');
        },
    },
};
</script>
