import { getHttp } from './fetchApi';

export const General = {
    URL: '/general.json',
    
    get(){
        return getHttp(`${this.URL}`)
    }

}
