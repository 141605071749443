<template>
    <div class="container-fluid block-interview block-text-standard">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <template v-for="(contentItem, index) in block.content" :key="'item-'+index">
              <div v-if="contentItem.text" class="col-12 col-lg-6" :class="[contentItem.type, {'offset-lg-3': index > 0}]" v-html="contentItem.text"></div>
              <image-single v-if="contentItem.type == 'image'" :block="contentItem.imageData" :theme-color="themeColor"></image-single>
            </template>
        </div>
    </div>
</template>

<script>
import imageSingle from '@/components/contentBlocks/ImageSingle';

export default {
  name: 'interview',
  components: {
      imageSingle
  },
  props: ['block', 'themeColor'],
}
</script>
