import { getHttp } from './fetchApi';

export const Page = {
    URL: '/page.json',
    get(pathArr){
        var entryUri = encodeURIComponent(pathArr.join('/'));
        return getHttp(`${this.URL}?uri=${entryUri}`)
    },
    getNavigation(){
        return getHttp(`${this.URL}/navigation.json`)
    }

}
