<template>
    <div class="container-fluid">
        <div class="row content-block-row">
            <div :class="block.fullWidth || false ? 'col-12' : 'col col-centered'">
              <div class="block block-image-link" :class="'block-image-'+ imageSize">
                  <div v-if="block.image && imageSrc && block.link" class="image-row row">
                      <div v-if="block.image && imageSrc" class="image-col" :class="'image-col-'+imageSize">
                          <component :is="block.link.type=='entry' ? 'router-link' : 'a'" :to="block.link.type=='entry' ? '/'+$url(block.link.href) : null" :href="block.link.type!='entry' ? block.link.href : null" :target="block.link.target" class="image-link-element image-wrapper">
                              <img :src="mobileImageSrc.src" :width="imageSrc.width" :height="imageSrc.height" :alt="block.image.title" class="mix-blend-mode img-fluid" :class="'img-size-'+ imageSize" :srcset="imageSrc.src == mobileImageSrc.src ? null : [mobileImageSrc.src+' '+mobileImageSrc.width+'w', imageSrc.src+' '+imageSrc.width+'w']" :sizes="imageSrc.src == mobileImageSrc.src ? null : ['(min-width: 576px) '+imageSrc.width+'w', '100vw']">
                              <span v-if="block.text" class="image-link-label">
                                  <span class="image-link-label-text" v-html="block.text"></span>
                              </span>
                          </component>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
    name: 'imageLink',
    props: ['block', 'themeColor'],
    data(){
        return {
            defaultImageSize : 'small',
            mobileImageSize: 'small'
        }
    },
    computed: {
        imageSize() {
            return this.block.imageSize ? this.block.imageSize : this.defaultImageSize;
        },
        imageSrc() {
            return this.block.image && this.block.image.srcs ? (this.block.image.srcs[this.imageSize] || null) : null;
        },
        mobileImageSrc() {
            return this.block.image && this.block.image.srcs ? (this.block.image.srcs[this.mobileImageSize] || null) : null;
        }
    },
}
</script>
