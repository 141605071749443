<template>
    <static-foundation-page-content class="newsletter-unsubscription-success">
        <h2>{{$tc('newsletter.pages.unsubscription.heading')}}</h2>
        <p>{{$tc('newsletter.pages.unsubscription.text')}}</p>
    </static-foundation-page-content>
</template>
<script>
import StaticFoundationPageContent from '@/components/foundation/StaticFoundationPageContent';

export default {
    name: 'NewsletterUnsubscriptionSuccess',
    components: {
        StaticFoundationPageContent
    },
}
</script>
