import { getHttp } from './fetchApi';

export const Events = {
    URL: '/events',

    // getAll(){
    //     return getHttp(`${this.URL}.json`)
    // },

    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    },

}
