<template>
    <!-- OLD, PROBABLY UNUSED, REMOVE IF SO -->
    <div class="container-fluid">
        <div class="row content-block-row">
            <div :class="block.fullWidth || false ? 'col-12' : 'col col-centered'">
              <div class="block block-links-project-journal-entries">
                  <router-link v-for="(item, index) in block.linkItems" :to="{ name: 'JournalEntry', params: { slug: item.slug, locale: $route.params.locale } }" :key="'link-'+index" class="link-item entry-preview-header bg-black d-flex flex-column" :class="'text-'+(item.themeColor || 'default-theme-color')">
                     <div class="entry-preview-meta"><h5>{{item.categoryTitle}}</h5></div>
                     <div class="entry-preview-title d-flex align-items-center justify-content-center">
                         <h2 class="text-center" v-html="item.title"></h2>
                     </div>
                 </router-link>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'linksProjectJournalEntries',
  props: ['block'],
}
</script>
