import axios from 'axios';
import { i18n } from '../i18n'

const BASE_URL = process.env.VUE_APP_API_URL;
var localePath = () => (i18n.global.locale != 'de') ? "/" + i18n.global.locale : '';

// Add interceptor if is craft live preview or preview
const urlParams = new URLSearchParams(window.location.search);
const craftPreview = urlParams.get('x-craft-live-preview') || urlParams.get('x-craft-preview');
const craftToken = urlParams.get('token');

if (craftPreview && craftToken) {
    axios.interceptors.request.use(
      (config) => {
        // Forward Craft Preview tokens to API
        const urlPath = config.url.replace(BASE_URL, '');
        if (urlPath != '/general.json' && urlPath != '/foundation/navigation.json') { // ignore general.json + navigation.json + journalTopics.json api calls
          const params = {
            'token': craftToken,
          }
          if (!config.params) {
            config.params = params
          }
          else {
            Object.keys(params).map(key => config.params[key] = params[key])
          }
        }
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )
}
export default async (url, method, options = {}) => {

    // notes :
    // this is the only function I have changed in order to be able to work on the project locally
    // the code is obviously not as concise as before, but it is much more practical for me this way
    // otherwise, this shouldn't affect how the website works on your end

    var urlPath = localePath() + url;
    var useCredentials = true;
    if (~process.env.VUE_APP_API_URL.indexOf("localhost")) {
        useCredentials = false;
        urlPath = encodeURIComponent(urlPath)
    }

    return axios({
        method: method.toUpperCase(),
        url: BASE_URL + urlPath,
        withCredentials: useCredentials,
        ...options,
    });
}
