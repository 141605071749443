<template>
    <div class="block block-video-native container-fluid">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-lg-6">
                <video width="320" height="240" controls :poster="block.image">
                    <source :src="block.video" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <p v-if="block.caption" class="text-sm">{{ block.caption }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'video',
    props: ['block'],
};
</script>
