<template>
    <!--block-preview-default-entry-->
    <router-link :to="{ name: 'JournalEntry', params: { slug: entry.slug, locale: $route.params.locale || '' } }" class="entry-preview-block block-preview-default-entry h-100 d-flex flex-column" :class="entryThemeClass" :style="cssStyle">
        <div v-if="entry.image" class="entry-preview-image">
            <img :src="entry.image.src" :alt="entry.image.title" :width="entry.image.width / 2" :height="entry.image.height / 2" />
        </div>
        <div class="entry-preview-header">
            <h2 v-html="entry.title"></h2>
        </div>
        <div class="entry-preview-text">
            <p>
                <span class="preview-text-interact">{{ entry.text }}</span>
            </p>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'PreviewBlockDefaultEntry',
    //components: {TextPreviewBlock},
    props: ['entry'],
    data() {
        return {};
    },
    computed: {
        cssStyle() {
            if (this.entry && this.entry.theme) {
                const secondaryColor = this.entry.theme.secondaryColor || '#FFF';
                return {
                    '--theme-primary-color': this.entry.theme.primaryColor || '#000',
                    '--theme-secondary-color': secondaryColor
                }
            } else {
                return null;
            }
        },
        entryThemeClass() {
            let themeClass = 'theme-default';
            if (this.entry && this.entry.theme) {
                themeClass = 'theme-custom-colors';
            }
            return themeClass;
        },
    },
    methods: {},
};
</script>
