<template>
    <div class="page" :class="['page-' + $route.name, view.pageClass, {'journal-start-animation-showing': view.showJournalStartAnimation}]" :style="view.pageCssVars">
        <SiteHeader ref="siteHeader" />
        <main id="site-main" class="site-main">
            <router-view v-slot="{ Component }">
                <transition name="view-transition" :duration="view.transition.duration">
                    <keep-alive>
                        <component :is="Component" :key="this.$route.fullPath" />
                    </keep-alive>
                </transition>
            </router-view>
        </main>
        <site-footer></site-footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
export default {
    name: "App",
    components: {
        SiteHeader,
        SiteFooter,
    },
    data() {
        return {
            transitionDuration: 4000,
            firstTimeExecuted: null,
            initialScrollT: false,
            customThemeViews: [ 'JournalEntry', 'EventEntry', 'JournalOverview', 'JournalCategoryArchive', 'FeaturedProjectEntry' ],
        };
    },
    computed: {
        ...mapGetters({
            'view':'view/get',
        }),
    },
    watch: {
        $route(to, from) {
            if (! this.customThemeViews.includes(to.name)) {
                this.$store.commit('view/setPageClass', '');
            }
            if (this.initialScrollT) {
                clearTimeout(this.initialScrollT);
            }
            var that = this;
            this.initialScrollT = setTimeout(function() {
                window.scrollTo({
                    top: !(to.meta.scrollPosition || false) ? 0 : to.meta.scrollPosition.top,
                });
                that.$nextTick(() => {
                    if (typeof that.$refs.siteHeader !== 'undefined') {
                        that.$refs.siteHeader.$el.classList.remove('cover');
                    }
                });
            }, this.view.transition.duration);

            // new nav update
            this.$store.commit("navigation/setMenuItemsActiveByPath", to.path);
        },
        "$i18n.locale": function (newVal, oldVal) {
            clearTimeout(this.firstTimeExecuted);
            this.loadStoreData();
            document.documentElement.lang = newVal;
        },
    },
    mounted() {
        var _this = this;
        this.firstTimeExecuted = setTimeout(async function () {
            _this.loadStoreData();
        }, 500);

        // add touch or no-touch class to body
        document.documentElement.classList.add(this.$isTouchDevice() && !this.$canHoverDevice() ? "touch" : "no-touch");
        document.documentElement.lang = this.$i18n.locale;
    },
    methods: {
        async loadStoreData() {
            this.$store.dispatch("general/fetch");
            //this.$store.dispatch("journalCategories/fetch");
            await this.$store.dispatch("navigation/fetch");
            this.$store.commit("navigation/setMenuItemsActiveByPath", this.$route.path);
        },
    },
};
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
</style>
