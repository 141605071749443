<template>
    <div v-if="block.fullWidth" class="block block-image-single" :class="'block-image-single-' + imageSize">
        <div class="container-fluid">
            <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
        </div>
        <figure v-if="block.image" class="image-wrapper" :class="themeColor ? 'bg-themeColor' : null">
            <picture>
                <source media="(max-width: 575px)" :srcset="block.image.srcs.mobile.src" />
                <source media="(max-width: 768px)" :srcset="block.image.srcs.tablet.src" />
                <img :src="block.image.srcs.desktop.src" :width="block.image.srcs.desktop.width" :height="block.image.srcs.desktop.height" :alt="block.image.title" class="img-fluid" :class="'img-size-' + imageSize" />
            </picture>
        </figure>
        <div v-if="block.caption" class="container-fluid image-caption" v-html="block.caption"></div>
    </div>
    <div v-else class="block block-image-single container-fluid" :class="'block-image-single-' + imageSize">
        <div class="row content-block-row">
            <div class="col-12 col-md-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12" :class="imageSize == 'medium' ? 'col-md-4 offset-md-1' : 'col-md-6'">
                <figure v-if="block.image" class="image-wrapper" :class="themeColor ? 'bg-themeColor' : null">
                    <picture>
                        <source media="(max-width: 575px)" :srcset="block.image.srcs.mobile.src" />
                        <source v-if="block.image.srcs.tablet || false" media="(max-width: 768px)" :srcset="block.image.srcs.tablet.src" />
                        <img :src="block.image.srcs.desktop.src" :width="block.image.srcs.desktop.width" :height="block.image.srcs.desktop.height" :alt="block.image.title" class="img-fluid" :class="'img-size-' + imageSize" />
                    </picture>
                </figure>
                <div v-if="block.caption" class="col-12 image-caption" v-html="block.caption"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'image',
    props: ['block', 'themeColor'],
    data() {
        return {
            defaultImageSize: 'small',
            mobileImageSize: 'small',
        };
    },
    computed: {
        imageSize() {
            //return typeof this.block.imageSize !== 'undefined' ? this.block.imageSize : (this.block.fullWidth ? 'full-width' : this.defaultImageSize);
            return this.block.imageSize ? this.block.imageSize : this.block.fullWidth ? 'full-width' : this.defaultImageSize;
        },
        imageSrc() {
            return this.block.image && this.block.image.srcs ? this.block.image.srcs[this.imageSize] || null : null;
        },
        mobileImageSrc() {
            return this.block.image && this.block.image.srcs ? this.block.image.srcs[this.mobileImageSize] || null : null;
        },
    },
};
</script>
