<template>
    <div class="block block-audio-native container-fluid" :class="themeColor ? 'bg-themeColor' : null">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-lg-6">
                <audio controls>
                    <source :src="block.audio" type="audio/mp3" />
                </audio>
                <p v-if="block.caption" class="text-sm">{{ block.caption }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'audio',
    props: ['block', 'themeColor'],
};
</script>
