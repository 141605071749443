<template>
    <static-foundation-page-content class="newsletter-subscription-verification-success">
        <h2>{{$tc('newsletter.pages.verification.heading')}}</h2>
        <p>{{$tc('newsletter.pages.verification.text')}}</p>
    </static-foundation-page-content>
</template>
<script>
import StaticFoundationPageContent from '@/components/foundation/StaticFoundationPageContent';

export default {
    name: 'NewsletterSubscriptionVerificationSuccess',
    components: {
        StaticFoundationPageContent
    },
}
</script>
