function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const view = {
    namespaced: true,
    state: {
        view: {
            transition: {
                duration:0,
            },
            pageClass: '',
            showJournalStartAnimation: true,
        },
    },
    getters: {
        get: state => {
            return state.view;
        },

    },
    mutations: {
        set: (state, view) => {
            state.view = view;
        },
        setTransitionDuration: (state, duration) => {
            state.view['transition']['duration'] = duration;
        },
        setPageClass: (state, cssClass) => {
            state.view['pageClass'] = cssClass;
        },
        setPageCssVars: (state, cssVars) => {
            const secondaryColor = cssVars.secondaryColor || null;
            state.view['pageCssVars'] = {
                '--theme-primary-color': cssVars.primaryColor || null,
                '--theme-secondary-color': secondaryColor,
                '--theme-secondary-color-rgba-50': secondaryColor ? hexToRGB(secondaryColor, '0.5') : null,
                '--theme-header-gradient-color-start': cssVars.headerColorGradient || null,
                '--theme-header-text-color': cssVars.headerTextColor || null,
            };
        },
        setJournalStartAnimationDisplayOption: (state, value) => {
            state.view['showJournalStartAnimation'] = value;
        },
    },
    actions: {},
}
