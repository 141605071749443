<template>
    <div class="journal-entry" :class="[entry ? 'journal-entry-' + entry.type : '']">
        <template v-if="entry">
            <journal-entry-header :entry="entry" :show-content="showPageHeaderContent" :key="entry.id"></journal-entry-header>

            <div class="entry-content" ref="entryContent">
                <div class="content-blocks">
                    <div v-if="entry.project" class="container-fluid block-text-standard block-text-lead entry-project-data text-4">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h4 class="text-small mb-0">{{ $tc('projects.organization') }}</h4>
                                <p class="my-0">{{ entry.project.organization || '–' }}</p>
                            </div>
                            <div class="col-6 col-md-3">
                                <h4 class="text-small mb-0">{{ $tc('projects.period') }}</h4>
                                <p class="my-0">{{ entry.project.period || '–' }}</p>
                            </div>
                            <div class="col-6 col-md-3">
                                <h4 class="text-small mb-0">{{ $tc('projects.amount') }}</h4>
                                <p class="my-0">CHF {{ entry.project.amount || '–' }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="entry.leadText" class="entry-leadText block block-text-standard block-text-lead container-fluid">
                        <div class="row content-block-row">
                            <div class="col-12 text-lead" v-html="entry.leadText"></div>
                        </div>
                    </div>

                    <div v-if="entry.credits" class="block entry-credits block-text-standard container-fluid">
                        <div class="row content-block-row">
                            <div class="col">
                                <div v-if="entry.credits" class="credits" v-html="entry.credits"></div>
                            </div>
                        </div>
                    </div>
                    <component v-for="(element, index) in entry.contentBlocks" :key="'element-' + index" :is="element.type" :block="element" :theme-color="entry.themeColor" :class="element.backgroundColor ? 'bg-' + element.backgroundColor : ''"></component>
                </div>
            </div>
            <journal-entry-related-articles :entry="entry"></journal-entry-related-articles>
        </template>
        <Spinner v-else />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JournalEntryHeader from '@/components/journal/JournalEntryHeader';
import JournalEntryRelatedArticles from '@/components/journal/JournalEntryRelatedArticles';
import Spinner from '@/components/Spinner';
import accordeon from '@/components/contentBlocks/Accordeon';
import imageSingle from '@/components/contentBlocks/ImageSingle';
import textStandard from '@/components/contentBlocks/TextStandard';
import infoBox from '@/components/contentBlocks/InfoBox';
import images2Cols from '@/components/contentBlocks/Images2Cols';
import imageGallery from '@/components/contentBlocks/ImageGallery';
import divider from '@/components/contentBlocks/Divider';
import quote from '@/components/contentBlocks/Quote';
import person from '@/components/contentBlocks/Person';
import additionalLinks from '@/components/contentBlocks/AdditionalLinks';
import interview from '@/components/contentBlocks/Interview';
import spacer from '@/components/contentBlocks/Spacer';
import youtubeVideo from '@/components/contentBlocks/YoutubeVideo';
import podcast from '@/components/contentBlocks/Podcast';
import iframe from '@/components/contentBlocks/Iframe';
import imageLink from '@/components/contentBlocks/ImageLink';
import infoDropdown from '@/components/contentBlocks/InfoDropdown';

import { FeauturedProject } from '@/http/FeauturedProject';

import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'FeaturedProjectEntry',
    components: {
        JournalEntryHeader,
        JournalEntryRelatedArticles,
        Spinner,
        textStandard,
        accordeon,
        imageSingle,
        infoBox,
        images2Cols,
        imageGallery,
        divider,
        quote,
        person,
        additionalLinks,
        interview,
        spacer,
        youtubeVideo,
        podcast,
        iframe,
        imageLink,
        infoDropdown,
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            entry: null,
            prevRoute: null,
            showPageHeaderContent: true,
            linksInited: false
        };
    },
    mounted() {
        this.getJournalEntry();
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        this.initPageContent();
    },
    deactivated() {
        this.resetPageContent();
    },
    updated() {
        this.$nextTick(() => {
            if (!this.linksInited) {
                this.initInternalLinks(this.$refs.entryContent);
                this.linksInited = true;
            }
        });
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from.name || null;
        });
    },
    methods: {
        async getJournalEntry() {
            try {
                var response = await FeauturedProject.get(this.$route.params.slug);
                this.entry = response.data;
                this.$nextTick(function () {
                    this.initPageContent();
                });
            } catch {
                this.$push('');
            }
        },
        returnToJournal() {
            if (this.prevRoute) {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: 'JournalOverview', params: { locale: this.$route.params.locale || '' } });
            }
        },
        initPageContent() {
            if (this.entry && typeof this.entry.themeColor !== 'undefined') {
                this.$el.classList.add(this.entry.themeColor);
                this.$store.commit('view/setPageClass', this.entry.themeColor);
            }
            if (this.entry && this.entry.metaData) {
                this.$setHeaderData(this.entry.metaData);
            }
            if (this.entry && this.entry.parent) {
                this.$store.commit('navigation/setPageRoot', this.entry.parent);
            }
            if (this.entry && this.entry.languageLinks) {
                this.$store.commit('navigation/setLangNav', this.entry.languageLinks);
            }
            this.showPageHeaderContent = true;
        },
        resetPageContent() {
            if (this.entry && typeof this.entry.themeColor !== 'undefined') {
                this.$el.classList.remove(this.entry.themeColor);
            }
            this.showPageHeaderContent = false;
        },
    },
};
</script>
