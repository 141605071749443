<template>
    <div v-if="block.linkItems" class="block block-additional-links container-fluid">
        <div class="row content-block-row">
            <div class="col-12 col-lg-3">
                <h2 class="section-heading" v-if="block.sectionHeading">{{ block.sectionHeading }}</h2>
            </div>
            <div class="col-12 col-lg-6">
                <!--<div v-if="block.heading" class="row">
                <h2 v-html="block.heading" class="block-title"></h2>
                </div>-->
                <component v-for="(item, index) in block.linkItems" :key="'link-' + index" class="row link-item" :is="item.link.type == 'entry' ? 'router-link' : 'a'" :to="item.link.type == 'entry' ? '/' + $url(item.link.href) : null" :href="item.link.type != 'entry' ? item.link.href : null" :target="item.link.type != 'entry' ? '_blank' : null">
                    <div class="col-2">
                        <div class="ratio ratio-1x1 link-media" :class="[block.backgroundColor == 'themeColor' ? 'bg-themeContrastColor' : 'bg-themeColor', { circle: item.icon }]">
                            <img v-if="item.image" class="link-image" :src="item.image.src" :alt="item.image.title" :width="item.image.width" :height="item.image.height" />
                            <div v-if="item.icon" class="link-icon" :class="'link-icon-' + item.icon"></div>
                        </div>
                    </div>
                    <div class="col-10 link-text-col text-sm">
                        <h3 v-if="item.title" class="link-title" v-html="item.title"></h3>
                        <div v-if="item.text" class="link-text" v-html="item.text"></div>
                    </div>
                </component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'additionalLinks',
    props: ['block', 'themeColor'],
};
</script>
