<template>
    <div v-if="block.entries" class="block block-featured-projects container-fluid">
        <div class="row content-block-row">
            <div v-for="(entry, index) in displayedProjects" class="col-12 col-sm-6 col-md-4" :key="'featured-project'+index">
                <router-link :to="{ name: 'FeaturedProjectEntry', params: { slug: entry.slug, locale: $route.params.locale || '' } }" class="featured-project-preview-block" :class="entry.themeColor ? entry.themeColor : 'theme-default'">
                    <div v-if="entry.image" class="entry-preview-image bg-themeColor">
                        <img class="mix-blend-mode-permanent w-100 h-auto" :src="entry.image.src" :alt="entry.image.title" :width="entry.image.width / 2" :height="entry.image.height / 2" />
                    </div>
                    <div class="entry-preview-text">
                        <h3 v-html="entry.title"></h3>
                        <p v-if="entry.organization" v-html="entry.organization"></p>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-if="!displayAllProjects" class="project-list-load-more">
            <span class="load-more-btn" @click="loadAllProjects">
                {{$tc('projects.list.loadMore')}}<br />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.045 17.612">
                    <g fill="none" stroke="currentColor" stroke-width="1.4" data-name="Group 4457" opacity="1">
                        <path d="M8.523 0v16.622" data-name="Line 2552" />
                        <path d="m.494 8.594 8.027 8.028 8.029-8.028" data-name="Path 2864" />
                    </g>
                </svg>
            </span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'featuredProjects',
    props: ['block'],
    data () {
        return {
            initiallyDisplayedProjectsCount: 6,
            displayAllProjects: false,
        };
    },
    computed: {
        displayedProjects() {
            if (this.displayAllProjects || this.initiallyDisplayedProjectsCount > this.block.entries.length) {
                return this.block.entries;
            } else {
                return this.block.entries.slice(0, this.initiallyDisplayedProjectsCount);
            }
        },
    },
    mounted() {
        this.displayAllProjects = this.block.entries.length <= this.initiallyDisplayedProjectsCount;
    },
    methods: {
        loadAllProjects(index) {
            this.displayAllProjects = true;
        },
    },
}
</script>
