<template>
    <div class="container-fluid block block-text2cols">
        <div class="row content-block-row">
            <div class="col-12 col-sm-6" v-html="block.textLeft"></div>
            <div class="col-12 col-sm-6" v-html="block.textRight"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'text2cols',
  props: ['block'],
}
</script>
