<template>
    <div class="container-fluid entry-header d-flex flex-column justify-content-center align-items-center">
        <div class="text-heading-md position-absolute animation-heading">
            <h1 class="entry-title" v-html="entry.title"></h1>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HeaderMultimediaHeaderTwoCols',
    props: {
        entry: null,
    },
};
</script>
