<template>
    <div class="container-fluid block block-divider">
        <div class="row content-block-row">
            <div class="col-12 col-lg-6 offset-lg-3">
                <div class="divider-line"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'divider',
  props: ['block'],
}
</script>
