import { Foundation } from '@/http/Foundation';

export const navigation = {
    namespaced: true,
    state: {
        menu: [],
        metaMenu: [],
        active: '',
        pageRoot: null,
        langNav: []
    },
    getters: {
        getMenu: (state) => {
            return state.menu;
        },
        getMetaMenu: (state) => {
            return state.metaMenu;
        },
        getActive: (state) => {
            return state.menu.filter((item) => item.active)[0];
        },
        getPageRoot: (state) => {
            return state.pageRoot;
        },
        getLangNav: (state) => {
            return state.langNav;
        },
    },
    mutations: {
        set: (state, menues) => {
            state.menu = menues.main || [];
            state.metaMenu = menues.meta || [];
        },
        setPageRoot: (state, entryData) => {
            state.pageRoot = entryData || null;
        },
        setLangNav: (state, $langNav) => {
            state.langNav = $langNav || [];
        },
        // setMenuItemActiveByIndex: (state, index) => {
        //     for (var i = 0; i < state.menu.length; i++) {
        //         if (i != index) {
        //             state.menu[i]['active'] = false;
        //         }
        //     }
        //     state.menu[index]['active'] = true;
        // },
        setMenuItemsActiveByPath: (state, path) => {
            for (var i = 0; i < state.menu.length; i++) {
                let isActive = false;
                if (state.menu[i]['link'] == path) {
                    isActive = true;
                }
                if (typeof state.menu[i].children !== 'undefined') {
                    for (var y = 0; y < state.menu[i].children.length; y++) {
                        if (state.menu[i].children[y].link == path) {
                            state.menu[i].children[y]['active'] = true;
                            isActive = true;
                        } else {
                            state.menu[i].children[y]['active'] = false;
                        }
                    }
                }
                state.menu[i]['active'] = isActive;
            }
        },
    },
    actions: {
        async fetch({ commit }, slug) {
            const { data } = await Foundation.getNavigation();
            commit('set', data);
        },
    },
};
